<template>
  <div>
    <el-row>
        <el-col style="text-align:left">
            <SearchBar>
              <el-select size="small" v-model="searchParams.role" style="width: 280px;">
                <el-option
                  v-for="item in roleList"
                  v-bind:key="item.value"
                  v-bind:label="item.label"
                  v-bind:value="item.value">
                </el-option>
              </el-select>
              <el-input size="small" v-model="searchParams.keyword" placeholder="ユーザー名 / グループ名" clearable style="width:280px;margin-left: 15px;"></el-input>
              <el-button size="small" type="primary" style="margin-left: 15px;" icon="el-icon-search" round @click="handleSearch" >{{$t('search.search')}}</el-button>
              <el-button size="small" type="info" style="margin-left: 5px;" round @click="handleReset">{{$t('search.reset')}}</el-button>
            </SearchBar>
        </el-col>
    </el-row>
    <el-row>
      <el-col :span="6">
        <span class="dash-value dash-num-blue" style="margin-top: 20px">{{total}}</span>&nbsp;
        <span class="dash-title">{{$t('listUser.user')}}</span>&nbsp;
      </el-col>
      <el-col :span="18">
        <div class="searchBar">
          <slot><el-button type="primary" size="small" round icon="el-icon-plus" @click="addUser">{{$t('listUser.add')}}</el-button></slot>
        </div>
      </el-col>
    </el-row>
    <el-table :data="items" style="width: 100%;padding: 10px 10px 10px 10px;">
      <!--<el-table-column prop="loginName" :label="$t('listUser.loginName')"></el-table-column>-->
      <el-table-column prop="name" :label="$t('listUser.name')"></el-table-column>
      <el-table-column prop="role" :label="$t('listUser.role')">
        <template slot-scope="scope">
          <span v-if="scope.row.role === 'A'">{{$t('addUser.A')}}</span>
          <span v-if="scope.row.role === 'B'">{{$t('addUser.B')}}</span>
          <span v-if="scope.row.role === 'C'">{{$t('addUser.C')}}</span>
          <span v-if="scope.row.role === 'T'">{{$t('addUser.T')}}</span>
          <span v-if="scope.row.role === '1'">システム管理員</span>
          <span v-if="scope.row.role === '2'">PP（庶務）メンバー</span>
          <span v-if="scope.row.role === '3'">PP（営業）メンバー</span>
          <span v-if="scope.row.role === '4'">代理店社員</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('listUser.group')" min-width="160px">
        <template slot-scope="scope">
          <div v-for="(val,index) in scope.row.groupNames" :key="index">
            <span>{{val}}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="mail" :label="$t('addUser.mail1')"></el-table-column>
      <el-table-column prop="mobile" :label="$t('addUser.mobile')"></el-table-column>
      <el-table-column :label="$t('listUser.process')" min-width="250px">
        <template slot-scope="scope">
          <el-button size="mini" type="warning" @click="resetPwd(scope.row.id)" plain round>パスワード再発行</el-button>
          <el-button size="mini" type="primary" @click="update(scope.row.id)" plain round>{{$t('listUser.edit')}}</el-button>
          <el-button size="mini" type="danger" @click="confirmDelete(scope.row.id)" plain round>{{$t('listUser.remove')}}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 10px">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="pageIndex"
        :page-size="pageSize"
        layout="total, prev, pager, next"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { listUser,resetUserPwd,deleteUser} from "@/api/admin";
import { mapState } from "vuex";
import { ROUTER_PATH } from "@/constants/common.js";

const defaultSearchParams = {
  role: "",
  keyword: "",
  pageIndex: 1,
  pageSize: 10
};
export default {
  name: "ListUser",
  data: () => {
    return {
      roleList: [
        {value:"", label: "すべて"},
        {value:"1", label: "システム管理員"},
        {value:"2", label: "PP（庶務）メンバー"},
        {value:"3", label: "PP（営業）メンバー"},
        {value:"4", label: "代理店社員"}
      ],
      searchParams: { ...defaultSearchParams },
      items: [],
      pageSize: 10,
      pageIndex: 1,
      total: 0,
    };
  },
  mounted() {
    this.refreshData();
  },
  computed: {
    ...mapState(["accountInfo"])
  },
  methods: {
    refreshData() {
      listUser({
        groupId: this.accountInfo.groupId,
        ...this.searchParams
      }).then(response => {
        this.total = response.data.totalCount;
        this.items = response.data.items;
      });
    },
    handleSizeChange(val){
      this.searchParams = { ...this.searchParams, pageSize: val };
      this.refreshData();
    },
    handleCurrentChange(val) {
      this.searchParams = { ...this.searchParams, pageIndex: val };
      this.refreshData();
    },
    handleSearch() {
        this.searchParams = {...this.searchParams, pageIndex: 1};
        this.refreshData();
    },
    handleReset() {
        this.searchParams = {...defaultSearchParams};
        this.refreshData();
    },
    addUser(){
      this.$router.push(ROUTER_PATH.ADD_USER);
    },
    resetPwd(uid){
      this.$confirm(this.$t('listUser.resetPwd'), this.$t('common.popup'), {
        confirmButtonText: this.$t('common.ok'),
        cancelButtonText: this.$t('common.cancel'),
        type: 'warning'
      }).then(() => {
        resetUserPwd({
            id: this.accountInfo.id,
            targetUserId: uid
          }).then(response => {
            this.$message({
              type: 'success',
              message: response.message
            });
            this.refreshData();
          });
      }).catch(() => {
                  
      });
    },
    update(uid){
      this.$router.push({path:ROUTER_PATH.UPDATE_USER, query:{id:uid}});
    },
    confirmDelete(uid) {
        this.$confirm(this.$t('listUser.confirm'), this.$t('common.popup'), {
          confirmButtonText: this.$t('common.ok'),
          cancelButtonText: this.$t('common.cancel'),
          type: 'warning'
        }).then(() => {
          deleteUser({
            id: this.accountInfo.id,
            targetUserId: uid
          }).then(response => {
            this.$message({
              type: 'success',
              message: response.message
            });
            this.refreshData();
          });
          
        }).catch(() => {
                  
        });
      }
  }
};
</script>
<style>
  .searchBar {
    margin: 0px 0px 10px 0px;
    /*padding: 10px 10px ;*/
    /*background-color: #f7f7f7;*/
    text-align: right;
  }
  </style>
